<template>
  <el-dialog title="连接数据库"
             :close-on-click-modal="false"
             :visible.sync="linkDBDialog"
             width="40%"
             append-to-body>
    <el-form :label-position="linkDBLabelPosition" label-width="200px" :model="linkDBForm" :rules="dbDataRule" ref="dataForm">
      <el-form-item style="margin-bottom: 20px" label="数据库类型：" prop="linkDBType">
        <el-select v-model="linkDBForm.linkDBType" placeholder="请选择数据库">
          <el-option
              v-for="item in linkDBOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="主机名称（IP地址）：" prop="ip">
        <el-input v-model="linkDBForm.ip" placeholder="请输入主机名称（IP地址）"></el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="端口号：" prop="port">
        <el-input v-model="linkDBForm.port" placeholder="请输入端口号"></el-input>
      </el-form-item>
      <el-form-item label="数据源名称" prop="alias">
        <el-input v-model="linkDBForm.alias" placeholder="请输入数据源名称"></el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="数据库名称：" prop="dbname">
        <el-input v-model="linkDBForm.dbname" placeholder="请输入数据库名称"></el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="数据库用户名：" prop="username">
        <el-input v-model="linkDBForm.username" placeholder="请输入数据库用户名"></el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="数据库密码：" prop="password">
        <el-input v-model="linkDBForm.password" placeholder="请输入密码" type="password"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
       <el-button @click="linkDBDialog = false">取 消</el-button>
       <el-button type="primary" @click="LinkDBSure">确 定</el-button>
       <el-button type="success" @click="linkDBTest">测 试</el-button>
      </span>
  </el-dialog>
</template>

<script>
import axios from "axios";
import { isEmail, isMobile } from '@/utils/validate'
export default {
  name: "linkDB",
  data() {
    var validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error('邮箱格式错误'))
      } else {
        callback()
      }
    }
    var validateMobile = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    return {
      currOperator: '',
      linkDBDialog: false,
      linkDBLabelPosition: 'left',
      linkDBForm: {
        ip: '',
        port: '',
        dbname: '',
        username: '',
        password: '',
        linkDBType: '',
      },
      linkDBOptions: [{
        value: 'mysql',
        label: 'mysql'
      }, {
        value: 'sql server',
        label: 'sql server'
      }, {
        value: 'oracle',
        label: 'oracle'
      }, {
        value: 'influxDB',
        label: 'influxDB'
      }
      ],
      data: {
        data: {},
        flag: 0,
      },
      dbDataRule: {
        linkDBType: [
          { required: true, message: '请选择数据库类型', trigger: 'blur' }
        ],
        ip: [
          { required: true, message: '请填写数据库ip', trigger: 'blur' }
        ],
        port: [
          { required: true, message: '请填写数据库端口', trigger: 'blur' }
        ],
        alias: [
          { required: true, message: '请填写数据源名称', trigger: 'blur' }
        ],
        dbname: [
          { required: true, message: '请填写数据库名称', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请填写数据库用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请填写数据库密码', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { validator: validateMobile, trigger: 'blur' }
        ]
      },
    }
  },
  created() {
    //获取session中的user的id
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
  },
  methods: {

    open() {
      this.linkDBDialog = true
    },
    close() {
      this.linkDBDialog = false
    },
    /**
     * 连接数据库窗口的确定事件
     */
    LinkDBSure() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let linkMessage;
          let data = {
            db: this.linkDBForm.linkDBType,
            ip: this.linkDBForm.ip,
            port: this.linkDBForm.port,
            dbname: this.linkDBForm.dbname,
            username: this.linkDBForm.username,
            password: this.linkDBForm.password,
            email: this.linkDBForm.email,
            mobile: this.linkDBForm.mobile,
            alias: this.linkDBForm.alias,
            uid: this.currOperator
          }
          this.postRequest("/addDataAccessTask/linkdb", data).then(response => {
            linkMessage = response.data
            this.$message({
              showClose: true,
              message: linkMessage,
              type: 'success'
            });
          }).then(error => {
            let flag = 0
            if (this.linkDBForm.linkDBType === 'mysql') {
              flag = 0
              this.postRequest("/addDataAccessTask/selectMysqlDBTable",data).then(response => {
                this.data.data = JSON.parse(JSON.stringify(response.data))
                this.data.flag = flag
              }).then(error => {
                this.$emit("linkDatabase", this.data)
              });
            } else if (this.linkDBForm.linkDBType === 'sql server') {
              flag = 1
              this.postRequest("/addDataAccessTask/selectSqlServerDBTable",data).then(response => {
                this.data.data = JSON.parse(JSON.stringify(response.data))
                this.data.flag = flag
              }).then(error => {
                this.$emit("linkDatabase", this.data)
              });
            } else if (this.linkDBForm.linkDBType === 'oracle') {
              flag = 2
              this.postRequest("/addDataAccessTask/selectOracleDBTable?" ,data).then(response => {
                this.data.data = JSON.parse(JSON.stringify(response.data))
                this.data.flag = flag
              }).then(error => {
                this.$emit("linkDatabase", this.data)
              });
            } else if (this.linkDBForm.linkDBType === 'influxDB') {
              flag = 3
              this.postRequest("/addDataAccessTask/selectMysqlDBTable?uid=" + this.currOperator).then(response => {
                this.data.response = response.data
                this.data.flag = flag
              }).then(error => {
                // console.log(error)
              });
            }
          })
        }
      })
    },
    /**
     * 测试数据库的连接
     */
    linkDBTest() {
      let linkMessage;
      let data = {
        db: this.linkDBForm.linkDBType,
        ip: this.linkDBForm.ip,
        port: this.linkDBForm.port,
        dbname: this.linkDBForm.dbname,
        username: this.linkDBForm.username,
        password: this.linkDBForm.password
      }
      this.postRequest("/addDataAccessTask/linkDbTest", data).then(response => {
        linkMessage = response.data
        this.$alert(linkMessage, '测试结果', {
          confirmButtonText: '确定',
          // callback: action => {
          //   this.$message({
          //     type: 'info',
          //     message: `action: ${action}`
          //   });
          // }
        });
      }).then(error => {
        // console.log(error)
      })
    },
  },
}
</script>

<style scoped>

</style>
