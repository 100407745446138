<template>
  <el-dialog title="代码模板"
             :close-on-click-modal="false"
             :visible.sync="codeTemplateDialog"
             width="40%"
             append-to-body>
    <div>
      <codemirror
          ref="cm"
          v-model="code"
          :options="cmOptions"
      ></codemirror>

    </div>
    <span slot="footer" class="dialog-footer">
       <el-button @click="codeTemplateDialog = false">取 消</el-button>
       <el-button type="primary" @click="codeTemplateDialog = false">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
// 全局引入vue-codemirror
import {codemirror} from 'vue-codemirror';
// 引入css文件
import 'codemirror/lib/codemirror.css'
// 引入主题 可以从 codemirror/theme/ 下引入多个
import 'codemirror/theme/idea.css'
// 引入语言模式 可以从 codemirror/mode/ 下引入多个
import 'codemirror/mode/sql/sql.js';

// 搜索功能
// find：Ctrl-F (PC), Cmd-F (Mac)
// findNext：Ctrl-G (PC), Cmd-G (Mac)
// findPrev：Shift-Ctrl-G (PC), Shift-Cmd-G (Mac)
// replace：Shift-Ctrl-F (PC), Cmd-Alt-F (Mac)
// replaceAll：Shift-Ctrl-R (PC), Shift-Cmd-Alt-F (Mac)
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/dialog/dialog'
import 'codemirror/addon/search/searchcursor'
import 'codemirror/addon/search/search'
import 'codemirror/addon/search/jump-to-line'
import 'codemirror/addon/search/matchesonscrollbar'
import 'codemirror/addon/search/match-highlighter'
// 代码提示功能 具体语言可以从 codemirror/addon/hint/ 下引入多个
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/sql-hint';
// 高亮行功能
import 'codemirror/addon/selection/active-line'
import 'codemirror/addon/selection/selection-pointer'
// 调整scrollbar样式功能
import 'codemirror/addon/scroll/simplescrollbars.css'
import 'codemirror/addon/scroll/simplescrollbars'
// 自动括号匹配功能
import 'codemirror/addon/edit/matchbrackets'
// 全屏功能 由于项目复杂，自带的全屏功能一般不好使
import 'codemirror/addon/display/fullscreen.css'
import 'codemirror/addon/display/fullscreen'
// 显示自动刷新
import 'codemirror/addon/display/autorefresh'
// 多语言支持？
import 'codemirror/addon/mode/overlay'
import 'codemirror/addon/mode/multiplex'
// 代码段折叠功能
import 'codemirror/addon/fold/foldcode'
import 'codemirror/addon/fold/foldgutter'
import 'codemirror/addon/fold/foldgutter.css'

import 'codemirror/addon/fold/brace-fold'
import 'codemirror/addon/fold/comment-fold'
import 'codemirror/addon/fold/xml-fold.js';
import 'codemirror/addon/fold/indent-fold.js';
import 'codemirror/addon/fold/markdown-fold.js';
import 'codemirror/addon/fold/comment-fold.js';
// merge功能
import 'codemirror/addon/merge/merge.css'
import 'codemirror/addon/merge/merge'
// google DiffMatchPatch
import DiffMatchPatch from 'diff-match-patch'
import chooseTag from "@/views/modules/dataManage/dataAccessManage/chooseTag";
import linkDB from "@/views/modules/dataManage/dataAccessManage/linkDB";
import axios from "axios";
// DiffMatchPatch config with global
window.diff_match_patch = DiffMatchPatch;
window.DIFF_DELETE = -1;
window.DIFF_INSERT = 1;
window.DIFF_EQUAL = 0;
export default {
  name: "codeTemplate",
  props:["allTaskData"],
  components: {
    codemirror,
  },
  data() {
    return {
      codeTemplateDialog: false,
      code: '',
      cmOptions: {
        mode: 'text/x-python',// 语言及语法模式
        theme: 'idea',  // 主题
        line: true,  // 显示行数
        lineNumbers: true, // 软换行
        lineWrapping: true, // tab宽度
        tabSize: 4,
        foldGutter: true, // 启用行槽中的代码折叠
        autoCloseBrackets: true, // 自动闭合符号
        styleActiveLine: true, // 显示选中行的样式
        lint: true,
        gutters: [
          "CodeMirror-lint-markers",//代码错误检测
          "CodeMirror-linenumbers",
          "CodeMirror-foldgutter",//展开收起
        ],
        hintOptions: {
          completeSingle: false,// 避免由于提示列表只有一个提示信息时，自动填充
          tables: {
            "table1": ["c1", "c2"],// 不同的语言支持从配置中读取自定义配置 sql语言允许配置表和字段信息，用于代码提示
          },
        },
        scrollbarStyle: 'overlay',// 调整scrollbar样式功能
        matchBrackets: true // 自动括号匹配功能
      },
    }
  },
  created() {
  },
  methods: {
    open(id) {
      console.log(id)
      if(this.code===''){
        this.postRequest("/addDataAccessTask/codeTemplate?taskId=" +id).then(response => {
          this.code = JSON.parse(JSON.stringify(response.data))
        }).then(error => {
          // console.log(error)
        })
      }
      this.codeTemplateDialog = true
    },
    close() {
      this.codeTemplateDialog = false
    },
  }
}
</script>

<style scoped>

</style>
